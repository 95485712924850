<template>
  <div>
    <v-simple-table class="table-padding-2-no-top">
      <thead class="v-data-table-header">
        <tr>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.phone_number')"
              :placeholder="$t('labels.phone_number')"
              name="buyer_phone"
              sort-name="buyer_phone"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.customer')"
              :placeholder="$t('labels.customer')"
              name="buyer_name"
              sort-name="buyer_name"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.creator_name')"
              :placeholder="$t('labels.creator_name')"
              name="identity_name"
              sort-name="identity_name"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.note')"
              :placeholder="$t('labels.note')"
              name="note"
              sort-name="note"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <SelectFilter
              :label="$t('labels.discount_by')"
              :placeholder="$t('labels.discount_by')"
              :options="voucherTypeOptions"
              name="voucher_type"
              sort-name="voucher_type"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilterFromTo
              :label="$t('labels.reduce')"
              :placeholder="$t('labels.reduce')"
              name="voucher_value"
              sort-name="voucher_value"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilterFromTo
              :label="$t('labels.bill_from')"
              :placeholder="$t('labels.bill_from')"
              name="discount_from"
              sort-name="discount_from"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 135px">
            <SelectFilter
              :label="$t('labels.is_discount_sale_product')"
              :placeholder="$t('labels.is_discount_sale_product')"
              :options="yesNoOptions"
              name="is_discount_sale_product"
              sort-name="is_discount_sale_product"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter
              :label="$t('labels.start')"
              :placeholder="$t('labels.start')"
              name="start_time"
              sort-name="start_time"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter
              :label="$t('labels.end')"
              :placeholder="$t('labels.end')"
              name="end_time"
              sort-name="end_time"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.voucher_promotion')"
              :placeholder="$t('labels.voucher_promotion')"
              name="code"
              sort-name="code"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.order_0')"
              :placeholder="$t('labels.order_0')"
              name="order_tracking"
              sort-name="order_tracking"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 120px">
            <SelectFilter
              :label="$t('labels.status')"
              :placeholder="$t('labels.status')"
              :options="statusOptions"
              name="status"
              sort-name="status"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 120px">
            <SelectFilter
              :label="$t('labels.time')"
              :placeholder="$t('labels.time')"
              :options="programTimeOptions"
              name="type"
              sort-name="type"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th>
            <v-btn small color="success" @click="showDialogAddVoucher">
              {{ $t("labels.add") }}
            </v-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-center"
          v-for="(item, index) in items"
          :key="`${index}_${item.id}`"
        >
          <td>{{ item.buyer_phone }}</td>
          <td>{{ item.buyer_name }}</td>
          <td>{{ item.identity_name }}</td>
          <td>{{ item.note }}</td>
          <td>{{ getVoucherTypeText(item).text }}</td>
          <td>
            <span v-if="item.discount_percent">
              {{ item.discount_percent * 100 }} %
            </span>
            <span v-else-if="item.discount_value">
              {{ formatNumber(item.discount_value) }} đ
            </span>
          </td>
          <td>{{ formatNumber(item.discount_from) }} đ</td>
          <td>{{ item.is_discount_sale_product ? "Yes" : "No" }}</td>
          <td>{{ formatDateTime(item.start_time) }}</td>
          <td>{{ formatDateTime(item.end_time) }}</td>
          <td>
            <span class="font-weight-medium">{{ item.code }}</span>
          </td>
          <td>#</td>
          <td>
            <div :class="getStatusText(item).color">
              {{ getStatusText(item).text }}
            </div>
          </td>
          <td>
            <div v-if="item.status === 1" :class="getProgramText(item).color">
              {{ getProgramText(item).text }}
            </div>
          </td>
          <td>
            <v-btn
              v-if="item.status && item.type"
              color="error"
              x-small
              @click="voucherCancel(item)"
            >
              {{ $t("labels.cancel") }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div class="text-center mt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="4"
      ></v-pagination>
    </div>

    <v-dialog v-model="addVoucherDialog" persistent max-width="100vw">
      <VoucherCreateList
        v-if="addVoucherDialog"
        @cancel="hideDialogAddVoucher"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { YES_NO_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "VoucherConfig",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    VoucherCreateList: () => import("@/components/pos/VoucherCreateList"),
  },
  data: () => ({
    items: [],
    filters: {},
    page: 1,
    totalPage: 1,
    isLoading: false,
    addVoucherDialog: false,
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    programTimeOptions() {
      return [
        {
          text: "Còn hiệu lực",
          color: "success--text",
          value: 2,
        },
        {
          text: "Chưa đến hạn",
          color: "primary--text",
          value: 1,
        },
        {
          text: "Quá hạn",
          color: "error--text",
          value: 0,
        },
      ];
    },
    statusOptions() {
      return [
        {
          text: "Đã sử dụng",
          color: "primary--text",
          value: 2,
        },
        {
          text: "Chưa sử dụng",
          color: "success--text",
          value: 1,
        },
        {
          text: "Đã hủy",
          color: "error--text",
          value: 0,
        },
      ];
    },
    voucherTypeOptions() {
      return [
        {
          text: "Theo %",
          color: "primary--text",
          value: 2,
        },
        {
          text: "Theo giá trị",
          color: "success--text",
          value: 1,
        },
      ];
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    showDialogAddVoucher() {
      this.addVoucherDialog = true;
    },
    hideDialogAddVoucher() {
      this.addVoucherDialog = false;
    },
    getProgramText(item) {
      return this.programTimeOptions.find((p) => p.value === item.type);
    },
    getStatusText(item) {
      return this.statusOptions.find((p) => p.value === item.status);
    },
    getVoucherTypeText(item) {
      if (item.discount_percent) {
        return this.voucherTypeOptions.find((p) => p.value === 2);
      } else {
        return this.voucherTypeOptions.find((p) => p.value === 1);
      }
    },
    async voucherCancel(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_voucher"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-cancel-voucher", {
          id: item.id,
        });
        this.isLoading = false;
        this.getList();
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/pos-get-vouchers", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
